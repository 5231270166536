import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'

import './SocialShare.sass'

const SocialShare = ({ url, title, twitterHandle }) => (
  <div className="social-share-container">
    <FacebookShareButton url={url} quote={title} className="social-share-item">
      <FacebookIcon size={46} round />
    </FacebookShareButton>
    <TwitterShareButton
      url={url}
      title={title}
      className="social-share-item"
      via={twitterHandle.split('@').join('')}
    >
      <TwitterIcon size={46} round />
    </TwitterShareButton>
    <RedditShareButton
      url={url}
      title={title}
      windowWidth={660}
      windowHeight={460}
      className="social-share-item"
    >
      <RedditIcon size={46} round />
    </RedditShareButton>
    <LinkedinShareButton url={url} className="social-share-item">
      <LinkedinIcon size={46} round />
    </LinkedinShareButton>
    <EmailShareButton url={url} subject={title} className="social-share-item">
      <EmailIcon size={46} round />
    </EmailShareButton>
  </div>
)

SocialShare.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  twitterHandle: PropTypes.string.isRequired,
}

export default SocialShare
