import React, { createRef, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { graphql } from 'gatsby'
import NavContainer from '../components/NavContainer'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HTMLContent from '../components/HTMLContent'
import RelatedPostTile from '../components/RelatedPostTile'
import SocialShare from '../components/SocialShare'
import {
  featuredImageProps,
  frontmatterProps,
  fieldsProps,
  siteProps,
} from '../proptypes'
import { removeTrailingSlash, getValidDates } from '../utils'

const BlogPostTemplate = ({
  title,
  featuredImage,
  slug,
  url,
  twitterHandle,
  html,
  subnav,
  inlineImages,
  date,
  dateModified,
  related,
}) => {
  const PostClasses = [
    'content',
    'content-blog',
    `content-blog-${slug
      .split('/')
      .filter((i) => i)
      .join('-')}`,
    subnav && subnav.length ? 'has-subnav' : '',
  ]
    .filter((i) => i)
    .join(' ')

  // count the number of headers
  const [headerRefs] = useState((subnav || []).map(() => createRef()))
  const heroRef = useRef()

  return (
    <div id="content" className={PostClasses}>
      <NavContainer subnav={subnav} targetRefs={[heroRef, ...headerRefs]} />
      <article>
        <Hero
          header={title}
          featuredImage={featuredImage}
          ref={heroRef}
          date={date}
          dateModified={dateModified}
        />
        <div className="main-wrapper">
          <main id="main" className="main" role="main">
            <HTMLContent
              headerRefs={headerRefs}
              subnav={subnav}
              content={html}
              inlineImages={inlineImages}
            />

            <hr />
            <SocialShare
              url={url}
              title={title}
              twitterHandle={twitterHandle}
            />
            {!!related && !!related.length && (
              <div id="related-posts">
                <hr />
                <h3>Related Posts</h3>
                <div className="grid">
                  {related.map(
                    ({
                      fields: { slug },
                      frontmatter: { title, featuredImage },
                    }) => (
                      <RelatedPostTile
                        key={uuidv4()}
                        title={title}
                        slug={removeTrailingSlash(slug)}
                        src={
                          !!featuredImage && !!featuredImage.src
                            ? featuredImage.src
                            : null
                        }
                        alt={
                          !!featuredImage && !!featuredImage.alt
                            ? featuredImage.alt
                            : null
                        }
                      />
                    ),
                  )}
                </div>
              </div>
            )}
          </main>
        </div>
      </article>
    </div>
  )
}

const relatedProps = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    featuredImage: featuredImageProps,
  }).isRequired,
})

BlogPostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  featuredImage: featuredImageProps,
  slug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  twitterHandle: PropTypes.string.isRequired,
  subnav: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      refIndex: PropTypes.number.isRequired,
    }),
  ),
  date: PropTypes.instanceOf(moment().constructor).isRequired,
  dateModified: PropTypes.instanceOf(moment().constructor).isRequired,
  related: PropTypes.arrayOf(relatedProps),
}

const BlogPost = ({ data }) => {
  const {
    frontmatter: { title, featuredImage, date },
    fields: { inlineImages, slug, gitAuthorTime, gitCreatedTime },
    html,
  } = data.markdownRemark
  const { location, twitterHandle } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const dates = getValidDates(date, gitAuthorTime, gitCreatedTime)
  const postProps = {
    title,
    featuredImage,
    slug: location.slug,
    url: `${location.origin}${location.slug}`,
    inlineImages,
    html,
    twitterHandle,
    date: dates.date,
    dateModified: dates.dateModified,
    related: data.related.edges
      .filter(
        ({ node }) => removeTrailingSlash(node.fields.slug) !== location.slug,
      )
      .slice(0, 4)
      .map(({ node }) => ({ ...node })),
  }
  postProps.subnav = (html.match(/<h2[^>]*>(.*)<\/h2>/g) || [])
    .map((item) => item.replace(/<[^>]*>/g, '').replace(/<\/{^>]*}>/g, ''))
    .map((item, i) => ({
      label: item,
      link: item
        .split(' ')
        .map((word) => word.toLowerCase().replace(/[^a-z]/g, ''))
        .filter((x) => x)
        .join('-'),
      refIndex: i,
    }))
  // console.log(postProps.subnav)
  return (
    <Layout location={location} data={data}>
      <BlogPostTemplate {...postProps} />
    </Layout>
  )
}

BlogPost.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: siteProps.isRequired,
    markdownRemark: PropTypes.shape({
      fields: fieldsProps.isRequired,
      frontmatter: frontmatterProps.isRequired,
      html: PropTypes.string.isRequired,
    }).isRequired,
    related: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: relatedProps,
        }),
      ),
    }),
  }).isRequired,
}

export default BlogPost

export const PostQuery = graphql`
  query BlogPostTemplate($id: String!, $primaryCategory: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        inlineImages {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      frontmatter {
        title
        ...seoFields
        ...featuredImageFields
        date(formatString: "YYYY-MM-DD")
        template
      }
      html
    }
    related: allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $primaryCategory } } }
      limit: 4
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 225, maxHeight: 225, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
